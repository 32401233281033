<template>
	<div>
		<el-row :gutter="24">
			<div class="d-flex a-center">
				<div style="margin-left: 22px;font-size: 18px;font-weight: 800;" class="py-2">对话框管理</div>
				<div style="margin-left: 20px;color: #0073ff;font-size: 14px;" @click="tjdhk">+添加对话框</div>
			</div>
			<el-col :span="6" v-for="(item,index) in fllist">
				<div class="d-flex align-items-center plugin-item" v-if="item.start == 0">
					<div style="width: 70%;margin-right: 2%;" @click="tzym(item.id)">
						<div style="font-weight: 600;font-size: 13px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.title}}</div>
					</div>
					<div style="width: 28%" class="d-flex">
						<div style="font-size: 13px;color: #0073ff;" @click="item.start = 1">更名</div>
						<div style="font-size: 13px;color: #ff0004;margin-left: 10px;" @click="scdhk(item.id)">删除</div>
					</div>
				</div>
				<div class="d-flex align-items-center plugin-item" v-else>
					<div style="width: 70%;margin-right: 2%;">
						<el-input type="text" v-model="newtitle" class="w-100" size="mini"></el-input>
					</div>
					<div style="width: 28%;" class="d-flex">
						<div style="font-size: 13px;color: #0073ff;" @click="genming(item.id)">保存</div>
						<div style="font-size: 13px;color: #ff0004;margin-left: 10px;" @click="item.start = 0">取消</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				fllist:[],
				newtitle:''
			}
		},
		// 页面初始化
		created(){
			this.getData()
		},
		computed: {
			...mapState({
				user: state => state.user.user,
				token: state => state.user.token
			}),
		},
		methods: {
			getData() {
				this.axios.get('/admin/dhk/'+this.user.id, {
					token: true
				}).then(res => {
					let data = res.data.data
					this.fllist = data.map(i=>{
						i.start = 0
						return i
					})
				})
			},
			
			tzym(e){
				this.$router.push({
					name: 'gpt_dhk',
					params: {e}
				})
			},
			genming(e){
				let data = {
					id:e,
					title:this.newtitle
				}
				this.axios.post('/admin/dhk/updata',data,{ token:true }).then(res => {
					location.reload();
				});
			},
			scdhk(e){
				let data = {
					id:e
				}
				this.axios.post('/admin/dhk/sc',data,{ token:true }).then(res => {
					location.reload();
				});
			},
			tjdhk(){
				let data = {
					user_id:this.user.id
				}
				this.axios.post('/admin/dhk/save',data,{ token:true }).then(res => {
					this.getData()
				});
			}
		},
	}
</script>

<style scoped>
	.a-center{
		align-items: center;
	}
	.plugin-item{
		padding: 15px 20px;
		    background-color: #fff;
		    border-radius: 6px;
		    margin: 0 4px 10px 4px;
		    display: flex;
		    align-items: center;
		    cursor: pointer;
		    transition: 0.2s ease;
		    box-shadow: 0 0px 10px #193e7b14;
	}
	.plugin-item:hover{
		transform: translateY(-3px);
		box-shadow:0 0 6px #999;
		transition:all 0.5s ease-out;
	}
</style>